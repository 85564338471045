<template>
	<div>
		<section class="section is-main-section">
			<finance-table
				ref="table"
				:settings="table.settings"
				:filter="table.filter"
				:page="table.page" />
		</section>
	</div>
</template>

<script>
	import {
		DownloadService,
		SiteService,
		TransactionService as Service,
	} from "@/services"
	import StatusChangeModal from "./StatusChange"
	import GroupAssignModal from "./GroupAssign"
	import LogModal from "./Log"
	import TransactionService from "@/services/transaction.service"
	import DepositStatus from "@/components/DepositStatus"

	export default {
		name: "WithdrawList",
		data() {
			return {
				table: {
					filter: [
						{
							type: "input",
							value: "WITHDRAW",
							hidden: true,
							field: "type",
						},
						{
							type: "input",
							value: null,
							field: "uuid",
							name: this.$t('def.transactionId'),
						},
						{
							type: "input",
							value: null,
							field: "processId",
							name: this.$t('def.method'),
						},
						{
							type: "input",
							value: null,
							field: "iban",
							name: this.$t('def.iban'),
						},
						{
							type: "input",
							value: null,
							field: "amount",
							name: this.$t('def.amount'),
						},
						{
							type: "input",
							value: null,
							field: "name",
							name: this.$t('def.user'),
						},
						{
							type: "input",
							value: null,
							field: "userId",
							name: this.$t('def.userId'),
						},
						{
							type: "enum",
							value: null,
							field: "status",
							name: this.$t('def.status'),
							enumType: "transactionTypes",
						},
						{
							type: "datepicker",
							value: [new Date(), new Date()],
							field: "dates",
							name: this.$t('def.requestDate'),
						},
						{
							type: "datepicker",
							value: [null, null],
							field: "updatedDates",
							name: this.$t('def.approveDate'),
						},
						{
							type: "input",
							value: null,
							field: "description",
							name: this.$t('def.method'),
						},
					],
					page: {
						title: this.$t('menu.withdrawals'),
						icon: "cash-multiple",
						actions: [
							{
								title: this.$t('def.filter'),
								type: "info",
								icon: "sync",
								action: () => {
									this.$refs.table.loadAsyncData()
								},
							},
							{
								title: "Export",
								type: "success",
								icon: "file-excel",
								perm: "Withdraw.ExcelExport",
								action: () => {
									this.getExportExcel()
								},
							},
						],
					},
					settings: {
						service: Service,
						method: "withdraw",
						columns: [
							{
								field: "status",
								label: this.$t('def.status'),
								sortable: true,
								renderComponent: DepositStatus,
							},
							{
								field: "site.name",
								label: this.$t('def.site'),
							},
							{
								field: "uuid",
								label: this.$t('def.transactionId'),
								sortable: true,
							},
							{
								field: "bankName",
								label: this.$t('def.bank'),
								renderHtml: true,
								column: "row",
								viewMethod: (val) => {
									let logo =
										process.env.VUE_APP_SITE_URL +
										"logos/banks/papara.png"
									if (
										val.withdrawType === "PAYFIX" ||
										val.description === "PAYFIX"
									)
										logo =
											process.env.VUE_APP_SITE_URL +
											"logos/banks/payfix.png"
									if (
										val.withdrawType === "TETHER" ||
										val.description === "TETHER"
									)
										logo =
											process.env.VUE_APP_SITE_URL +
											"logos/" +
											this.site +
											"/crypto.svg"
									if (
										val.withdrawType === "BANK_TRANSFER" ||
										val.withdrawType === "FOREX" ||
										val.description === "BANK_TRANSFER" ||
										val.description === "FOREX"
									)
										logo = val.bankList?.logo
									return `<img width="${
										val.withdrawType === "TETHER" ? 35 : 70
									}" style="max-width:70px" src="${logo}" />`
								},
							},
							{
								field: "accountName",
								label: this.$t('def.account'),
								sortable: true,
							},
							{
								field: "iban",
								label: this.$t('def.iban'),
								sortable: true,
							},
							{
								field: "name",
								label: this.$t('def.user'),
								sortable: true,
							},
							{
								field: "userId",
								label:this.$t('def.userId'),
								sortable: true,
							},
							{
								field: "total",
								label: this.$t('def.amount'),
								column: "row",
								width: "200px",
								renderHtml: true,
								viewMethod: (val) => {
									return `${this.currencies[val.fromCurrency].toLowerCase()}${this.numberFormat(val.total)}`
								},
							},
							{
								field: "withdrawGroup",
								label: this.$t('def.group'),
							},
							{
								field: "createdAt",
								label: this.$t('def.requestDate'),
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDateTimeS
									),
							},
							{
								field: "updatedAt",
								label: this.$t('def.approveDate'),
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDateTimeS
									),
							},
							{
								field: "actions",
								column: "id",
								label: this.$t('def.actions'),
								width: "100px",
								actions: [
									{
										title: this.$t('def.update'),
										type: "info",
										column: "row",
										perm: "Withdraw.StatusChange",
										icon: "file-edit-outline",
										action: (data) => {
											this.openModal({
												component: StatusChangeModal,
												props: {
													withdraw: { ...data },
												},
												close: this.$refs.table.loadAsyncData
											})
										},
									},
									{
										title: this.$t('def.logs'),
										type: "warning",
										column: "row",
										perm: "Withdraw.Log",
										icon: "format-list-text",
										action: (data) => {
											this.openModal({
												component: LogModal,
												props: {
													withdraw: { ...data },
												},
												styles: {
													width: 1000,
													height: 800,
												},
												// close: this.$refs.table.loadAsyncData
											})
										},
									},
									{
										title: this.$t('def.groupAssign'),
										type: "dark",
										column: "row",
										perm: "Withdraw.GroupAssign",
										action: (data) => {
											this.openModal({
												component: GroupAssignModal,
												props: {
													withdraw: { ...data },
												},
												// close: this.$refs.table.loadAsyncData
											})
										},
									},
								],
							},
						],
					},
				},
			}
		},
		computed: {
			insertListener() {
				return `transactionWithdrawInserted${
					this.$store.state.user.group !== "ALL"
						? this.$store.state.user.group
						: this.$store.state.user.siteId || ""
				}`
			},
			updateListener() {
				return `transactionWithdrawUpdated${
					this.$store.state.user.group !== "ALL"
						? this.$store.state.user.group
						: this.$store.state.user.siteId || ""
				}`
			},
		},
		async mounted() {
			if (!this.$store.state.user.siteId) await this.getSites()
		},
		methods: {
			getSites() {
				SiteService.all({ status: 1 }).then(({ data }) => {
					this.table.filter.unshift({
						type: "select",
						value: null,
						data,
						field: "siteId",
						name: "Site",
					})
				})
			},
			getExportExcel() {
				let filterParams = {}
				if (
					this.$refs.table.filter &&
					this.$refs.table.filter.length > 0
				) {
					this.$refs.table.filter.map((item) => {
						if (
							(item.value !== "" && item.value !== null) ||
							item.hidden === true
						) {
							if (item.type === "datepicker") {
								if (
									typeof item.value === "object" &&
									item.value.length > 0
								) {
									if (item.value[0] && item.value[1]) {
										const startDate = this.getIstanbulDate(
											item.value[0]
										)
										const endDate = this.getIstanbulDate(
											item.value[1],
											false
										)
										filterParams[item.field] = [
											startDate,
											endDate,
										]
									}
								} else {
									filterParams[item.field] = item.value
								}
							} else {
								filterParams[item.field] = item.value
							}
						}
					})
				}

				TransactionService.exportWithdrawExcel({
					...filterParams,
					offset: 180,
				}).then(({ data: { path, filename } }) => {
					DownloadService.download({ path }).then((res2) => {
						const url = URL.createObjectURL(
							new Blob([res2.data], {
								type: "application/vnd.ms-excel",
							})
						)
						const link = document.createElement("a")
						link.href = url
						link.setAttribute("download", filename)
						document.body.appendChild(link)
						link.click()
					})
				})
			},
		},
	}
</script>
